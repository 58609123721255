<div class="why-info">
  <h4 class="default-title mb-5">Mengapa Top Up Voucher di WarungVocer?</h4>

  <div class="why-list">
    <div class="item" *ngFor="let item of data; let i = index">
      <img class="icon" [src]="item.icon" alt="why-image-{{i+1}}">
      <h5 class="title">
        {{item.title}}
      </h5>
      <p class="desc">
        {{item.description}}
      </p>
    </div>
  </div>
</div>
